<template>
  <div>
    <b-row class="match-height">
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col md="1" style="margin-top: 10px; text-align: right;"><strong class="mt-3">SKPD</strong></b-col>
            <b-col md="11">
              <b-form-group>
                <v-select v-model="dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
                  @input="LoadPagu()" placeholder="Silahkan pilih SKPD sesuai aksen anda" :options="rs_dinas" />
              </b-form-group>
            </b-col>
          </b-row>
          <detail_dinas :pagu="pagu" v-show="pagu != ''" style="margin-bottom: -15px;">
          </detail_dinas>
        </b-card>

      </b-col>
    </b-row>
    <b-row style="margin-top: -15px;" v-if="objek_pendapatan == ''">
      <b-col>
        <b-card> ** Siahkan pilih SKPD unt masuk ke tahap pengajuan anggran</b-card>
      </b-col>
    </b-row>

    <b-row style="margin-top: -15px;" v-if="objek_pendapatan != ''">
      <b-col>
        <b-card>
          <daftarobjekpendapatan :rs_data="objek_pendapatan"></daftarobjekpendapatan>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import detail_dinas from './component/detailDinas.vue'
import {
  BTabs, BRow, BCol, BCard, BTab, BFormGroup
} from 'bootstrap-vue'
import daftarobjekpendapatan from './component/daftar_objek_pendapatan.vue'
export default {
  components: {
    BTabs, BRow, BCol, BCard, BTab, vSelect, detail_dinas, BFormGroup,
    daftarobjekpendapatan,
  },
  props: {

  },
  data() {
    return {
      rs_dinas: [],
      dinas: {},
      pagu: "",
      objek_pendapatan: [],
      id_pagu_pendapatan: '',
      id_master_dinas: '',
      rs_detail: {},
      id_objek_pendapatan: "",
      rincian_pendapatan: [],

      kode_akun: []
    }
  },
  mixins: [Base],
  mounted() {
    this.load_dinas()

  },
  methods: {

    async LoadPagu() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/realisasi_pendapatan/pagu_anggaran',
        data: {
          id_master_dinas: self.dinas.id_master_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.pagu = response.data.result;
          self.id_pagu_pendapatan = response.data.result.id_pagu_pendapatan
          self.id_master_dinas = response.data.result.id_master_dinas
          self.laod_objek();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async laod_objek() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/realisasi_pendapatan/load_data',
        data: {
          id_master_dinas: self.dinas.id_master_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.objek_pendapatan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_dinas() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/realisasi_pendapatan/master_kode_dinas',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_dinas = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>