<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>


    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="9">
              <h6 class="text-primary"> <strong><feather-icon icon="GridIcon" size='15' /> {{ data.item.kode_3 }} {{
                data.item.uraian
              }}</strong></h6>
              <h6 class="text-info"><small>Pagu Anggaran </small><br><strong><sup>Rp. </sup> {{
                Rp(data.item.anggaran_pendapatan) }}</strong>
              </h6>
            </b-col>
            <b-col md="3">
              <h6> </h6>
              <h6 class="text-primary mt-2"><small style="float: right;">Total Realisasi Anggaran </small><br><strong
                  style="float: right;"><sup>Rp.
                  </sup>
                  {{
                    Rp(data.item.total_realisasi.jumlah_realisasi) }}</strong>
              </h6>
            </b-col>
          </b-row>
          <b-alert variant="warning" show>
            <div class="alert-body">
              <b-row>
                <b-col md="2">
                  <h6><small>Januari</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran01) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Februar</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran02) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Maret</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran03) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>April</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran04) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Mei</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran05) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Juni</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan1_6.anggaran06) }}</strong>
                  </h6>
                </b-col>
              </b-row>
            </div>
          </b-alert>

          <b-alert variant="warning" show>
            <div class="alert-body">
              <b-row>
                <b-col md="2">
                  <h6><small>Juli</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran07) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Agustus</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran08) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Septermber</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran09) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Oktober</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran10) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>November</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran11) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6><small>Desember</small><strong><br><sup>Rp.</sup>{{ Rp(data.item.bulan7_12.anggaran12) }}</strong>
                  </h6>
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </template>
      </b-table>
    </b-col>

    <b-col cols="6">
      Total Data {{ totalRows }}
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    rs_data: {},
    totalRowsa: {}
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 25, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },
    totalRows: {
      get: function () {
        return this.totalRowss;
      },
      set: function (newValue) {
        return this.totalRowss = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>